<template>
  <div class="">
    <div class=" rounded py-2 bg-white">
      <div class=" items-center flex justify-between p-2 card mb-2">
        <div>
          <span class="flex items-center" >
            <span @click="redirectToList()" class="cursor-pointer pt-1"><i class="fas fa-arrow-left h-5 w-5"></i></span>
            <div class="heading-2 text-text1 pl-3">
              {{ pageDetailObj.pageName }}
            </div>
          </span>
        </div>
        <div>
          <button v-if="IsSuperAdmin && pageDetailObj.isActive" class=" bg-error font-bold heading-5 text-white py-3 px-4 rounded-3xl mr-4" @click="activeInActivePopup(false)" >Deactive Page</button>
          <button v-if="IsSuperAdmin && !pageDetailObj.isActive" class=" bg-success font-bold heading-5 text-white py-3 px-4 rounded-3xl mr-4" @click="activeInActivePopup(true)">Activate Page</button>
          <!-- <button v-if="IsSuperAdmin" class=" bg-secondary font-bold cta-text text-white py-3 px-6 rounded-3xl mr-4" @click="updatePageStr()">Update Page Structure</button> -->
          <button class="list_top_card_btn bg-primary font-bold heading-5 text-white py-3 px-3 rounded-3xl" @click="saveComponentData()">Save</button>
        </div>
      </div>
      <div>
        <div :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`" class="table_containder ">
          <div>
            <div v-if="pageDetailObj.pageSectionList !== null">
              <div v-for="(data, index) in pageDetailObj.pageSectionList" :key="index" class="border border-gray1 bg-white">
                <div class="flex cursor-pointer" @click="data.isOpen = !data.isOpen">
                  <div class="w-full flex  heading-3 font-bold text-text2 px-3 py-2">
                    Section Name: <span class="heading-3 pl-2 text-text2">{{ data.sectionName }}</span>
                  </div>
                  <div>
                    <span class="cursor-pointer pr-8" v-if="data.PageSectionFiledList.length > 0">
                      <topArrowIconSvg v-if="data.isOpen === true" :class="'mt-3'" :width="'20px'" :height="'20px'" />
                      <downArrowIconSvg v-if="data.isOpen === false" :class="'mt-3'" :width="'20px'" :height="'20px'" />
                    </span>
                  </div>
                </div>
                <div v-if="data.isOpen === true" class="dividerLight"></div>
                <div v-if="data.isOpen === true && data.PageSectionFiledList.length > 0" class="p-2">
                    <div class="flex grid grid-cols-12">
                        <div v-if="data.PageSectionFiledList.length > 0" class="box col-span-12">
                          <!-- <draggable :list="data.PageSectionFiledList" group="people" @start="drag = true" @end="dragArray" id='list'> -->
                            <div class="mx-2 heading-4 text-text2 border-gray-300 border rounded-lg my-3 px-1" v-for="(childrenData, indexData) in data.PageSectionFiledList" :key="indexData" v-show="childrenData.isDeleted === false">
                              <div class="heading-4 text-text2 items-center" v-if="childrenData.componentMasterId === 0">
                                <div class="flex items-center relative">
                                  <p class=" leading-10" style="width:265px">
                                    <span class="ml-4" >
                                      {{childrenData.fieldDisplayName}}
                                    </span>
                                  </p>
                                  <div class="absolute right-2 cursor-pointer" @click="removeElement(indexData, index, childrenData, data)">
                                    <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                  </div>
                                </div>
                                <Editor v-model="childrenData.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="childrenData.fieldDataType !== 'string' && childrenData.fieldDataType !== 'integer' && childrenData.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                <textarea v-model="childrenData.fieldDataDetail" v-if="childrenData.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-2 pl-2 my-2"></textarea>
                                <input v-model="childrenData.fieldDataDetail" type="number" v-if="childrenData.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-2 pr-2 my-2">
                                <span v-if="childrenData.fieldDataType === 'boolean'" class="my-2">
                                  <button :class="childrenData.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="childrenData.fieldDataDetail = 'true'">Yes</button>
                                  <button :class="childrenData.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="childrenData.fieldDataDetail = 'false'">No</button>
                                </span>
                              </div>
                              <div class="flex items-center relative" v-if="childrenData.componentMasterId > 0">
                                <p class="flex leading-10" style="width:665px">
                                  <span @click="childrenData.isOpen = !childrenData.isOpen" class="cursor-pointer" v-if="childrenData.componentMasterId !== 0 && childrenData.singleComponentList.length !== 0">
                                    <rightArrowIconSvg v-if="childrenData.isOpen === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                    <downArrowIconSvg v-if="childrenData.isOpen === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                  </span>
                                  <span :class="childrenData.componentFieldList !== null ? 'ml-4' : ''">{{childrenData.fieldDisplayName !== '' ? childrenData.fieldDisplayName : '---'}}</span>
                                </p>
                                <div v-if="childrenData.componentMasterId > 0">
                                  <span class="ml-4 heading-4 text-text2" v-if="childrenData.componentMasterId > 0">Component <span v-if="childrenData.isRepeated">(Repeatable)</span></span>
                                </div>
                                <div class="absolute right-2 cursor-pointer" @click="removeElement(indexData, index, childrenData, data)">
                                  <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                </div>
                              </div>
                              <div v-if="childrenData.isRepeated === false" :class="childrenData.isOpen === true && data.isOpen === true ? 'border border-gray-400 rounded-md p-2 mb-4 ml-8' : 'ml-8'">
                                <div class="heading-4 text-text2" v-for="(subChildrenData, indexChildrenData) in childrenData.singleComponentList.componentFieldList" :key="indexChildrenData" >
                                  <div v-if="subChildrenData.childComponentId === 0">
                                    <div class="flex items-center">
                                      <p class=" leading-10" style="width:265px">
                                        <span class="ml-4" v-if="childrenData.isOpen === true && data.isOpen === true">
                                          {{subChildrenData.fieldDisplayName}}
                                        </span>
                                      </p>
                                    </div>
                                    <Editor v-model="subChildrenData.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType !== 'string' && subChildrenData.fieldDataType !== 'integer' && subChildrenData.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                    <textarea v-model="subChildrenData.fieldDataDetail" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-2 pl-2 my-2"></textarea>
                                    <input v-model="subChildrenData.fieldDataDetail" type="number" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-2 pr-2 my-2">
                                    <span v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'boolean'" class="my-2">
                                      <button :class="subChildrenData.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subChildrenData.fieldDataDetail = 'true'">Yes</button>
                                      <button :class="subChildrenData.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subChildrenData.fieldDataDetail = 'false'">No</button>
                                    </span>
                                  </div>
                                  <div v-if="subChildrenData.childComponentId > 0 && childrenData.isOpen">
                                    <div class=" heading-4 text-text2 border border-gray-400 rounded-md m-2 p-2">
                                      <!-- <div v-if="!subChildrenData.isChildRepeatable">
                                        <div class="flex items-center">
                                          <div class="flex items-center leading-10" style="width:665px">
                                            <span @click="subChildrenData.openChild = !subChildrenData.openChild" class="cursor-pointer">
                                              <rightArrowIconSvg v-if="subChildrenData.openChild === false" :class="''" :width="'10px'" :height="'10px'" />
                                              <downArrowIconSvg v-if="subChildrenData.openChild === true" :class="''" :width="'10px'" :height="'10px'" />
                                            </span>
                                            <span class="pl-2">{{subChildrenData.childComponentSingle.childComponentName}}</span>
                                          </div>
                                        </div>
                                        <div  v-for="(subsubsubChild, subsubsubIndex) in subChildrenData.childComponentSingle.childComponentDetail" :key="subsubsubIndex" >
                                          <div v-if="subChildrenData.openChild">
                                            <div class="flex items-center">
                                              <p class="flex leading-10" style="width:665px">
                                                <span class="pl-2">{{subsubsubChild.fieldDisplayName}}</span>
                                              </p>
                                            </div>
                                            <Editor v-model="subsubsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="data.isOpen === true && subsubsubChild.fieldDataType !== 'string' && subsubsubChild.fieldDataType !== 'integer' && subsubsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                            <textarea v-model="subsubsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                            <input v-model="subsubsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                            <span v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                              <button :class="subsubsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubsubChild.fieldDataDetail = 'true'">Yes</button>
                                              <button :class="subsubsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubsubChild.fieldDataDetail = 'false'">No</button>
                                            </span>
                                          </div>
                                        </div>
                                      </div> -->
                                      <div v-if="subChildrenData.isChildRepeatable">
                                        <div class="flex items-center">
                                          <p class="flex leading-10" style="width:665px">
                                            <span @click="subChildrenData.openChild = !subChildrenData.openChild" class="cursor-pointer" v-if="data.isOpen === true " >
                                              <rightArrowIconSvg v-if="subChildrenData.openChild === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                              <downArrowIconSvg v-if="subChildrenData.openChild === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                            </span>
                                            <span class="pl-2">Child Component</span>
                                          </p>
                                        </div>
                                        <div :class="subChildrenData.openChild ? 'heading-4 text-text2 heading-4 border border-gray-400 rounded-md m-2 p-2' : ''" v-for="(subsubsubChild, subsubsubIndex) in subChildrenData.repeatedChildComponentList" :key="subsubsubIndex" >
                                          <div v-if="subChildrenData.openChild">
                                            <div class="flex items-center relative">
                                              <p class="flex leading-10" style="width:665px">
                                                <span @click="subsubsubChild.innerOpenChild = !subsubsubChild.innerOpenChild" class="cursor-pointer" v-if="subChildrenData.openChild === true" >
                                                  <rightArrowIconSvg v-if="subsubsubChild.innerOpenChild === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                  <downArrowIconSvg v-if="subsubsubChild.innerOpenChild === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                </span>
                                                <span class="pl-2">{{subsubsubChild.childComponentName}}</span>
                                              </p>
                                              <div class="absolute right-2 cursor-pointer" @click="removeChild(index, indexData,indexChildrenData, subsubsubIndex)">
                                                <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                              </div>
                                            </div>
                                            <div v-for="(subsubsubsubChild, subsubsubsubIndex) in subsubsubChild.childComponentDetail" :key="subsubsubsubIndex">
                                              <div v-if="data.isOpen === true && childrenData.isOpen && subChildrenData.openChild && subsubsubChild.innerOpenChild">
                                                <div class="flex items-center">
                                                  <p class=" leading-10" style="width:265px">
                                                    <span class="ml-8" >
                                                      {{subsubsubsubChild.fieldDisplayName}}
                                                    </span>
                                                  </p>
                                                </div>
                                                <Editor v-model="subsubsubsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType !== 'string' && subsubsubsubChild.fieldDataType !== 'integer' && subsubsubsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                                <textarea v-model="subsubsubsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                                <input v-model="subsubsubsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                                <span v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                                  <button :class="subsubsubsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubsubsubChild.fieldDataDetail = 'true'">Yes</button>
                                                  <button :class="subsubsubsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubsubsubChild.fieldDataDetail = 'false'">No</button>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="text-center text-primary cursor-pointer mb-3 mt-1" v-if="subChildrenData.isChildRepeatable === true" @click="cloneSelectedComp1(subChildrenData)">+ Add an entry (Child)</div>
                                    </div>
                                    <!-- <div class="text-center text-primary cursor-pointer my-3" v-if="subsubChild.isRepeated === true" @click="cloneSelectedComp1(subsubsubIndex, subChildIndex, indexChildrenData, indexData, index, childrenData, subsubIndex, subsubChild, subChildrenData)">+ Add an entry (Child)</div> -->
                                  </div>
                                </div>
                              </div>
                              <div v-if="childrenData.isRepeated === true" :class="childrenData.isOpen === true && data.isOpen === true && childrenData.repeatedComponentList.length > 0 ? 'border border-gray2 ml-8 rounded-md p-2 mb-4' : 'ml-8'">
                              <draggable :list="childrenData.repeatedComponentList" group="people" @start="drag = true" @end="dragArray" id='list'>
                                <div class="heading-4 text-text2 border border-gray-400 rounded-md m-2 p-2" v-for="(subChildrenData, subChildIndex) in childrenData.repeatedComponentList" :key="subChildIndex" >
                                  <div class="flex items-center relative">
                                    <p class="flex leading-10" style="width:665px">
                                      <span @click="subChildrenData.isOpen = !subChildrenData.isOpen" class="cursor-pointer" v-if="childrenData.isOpen === true && data.isOpen === true">
                                        <rightArrowIconSvg v-if="subChildrenData.isOpen === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                        <downArrowIconSvg v-if="subChildrenData.isOpen === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                      </span>
                                      <span class="pl-2">{{subChildrenData.componentName}}</span>
                                    </p>
                                    <!-- <p class=" leading-10" style="width:665px">
                                      <span class="ml-4 font-bold" >
                                        
                                        {{subChildrenData.componentName}}
                                      </span>
                                    </p> -->
                                    <div class="absolute right-8 cursor-pointer" @click="removeElementInside(subChildIndex, indexData, index)">
                                      <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                    </div>
                                    <div class="absolute right-1 cursor-pointer">
                                      <i class="fa-solid fa-grip-vertical text-gray3 h-4 w-4"></i>
                                    </div>
                                  </div>
                                  <div v-if="data.isOpen === true && childrenData.isOpen && subChildrenData.isOpen" >
                                    <div :class="subsubChild.childComponentId > 0 ? 'border border-gray-400 rounded-md m-2 p-2' : ''" class="heading-4 text-text2" v-for="(subsubChild, subsubIndex) in subChildrenData.componentFieldList" :key="subsubIndex" >
                                      <div v-if="subsubChild.childComponentId === 0">
                                        <div class="flex items-center">
                                          <p class=" leading-10" style="width:265px">
                                            <span class="ml-8">
                                              {{subsubChild.fieldDisplayName}}
                                            </span>
                                          </p>
                                        </div>
                                        <Editor v-model="subsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-4 pl-4 my-2" v-if="data.isOpen === true && subsubChild.fieldDataType !== 'string' && subsubChild.fieldDataType !== 'integer' && subsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                        <textarea v-model="subsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                        <input v-model="subsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                        <span v-if="data.isOpen === true && subsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                          <button :class="subsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubChild.fieldDataDetail = 'true'">Yes</button>
                                          <button :class="subsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubChild.fieldDataDetail = 'false'">No</button>
                                        </span>
                                      </div>
                                      <div v-if="subsubChild.childComponentId > 0">
                                        <!-- <div class=" heading-4 text-text2 border border-gray-400 rounded-md m-2 p-2"> -->
                                        <div class="">
                                          <!-- <div v-if="!subsubChild.isChildRepeatable">
                                            <div class="flex items-center">
                                              <div class="flex items-center leading-10" style="width:665px">
                                                <span @click="subsubChild.isOpen = !subsubChild.isOpen" class="cursor-pointer">
                                                  <rightArrowIconSvg v-if="subsubChild.isOpen === false" :class="''" :width="'10px'" :height="'10px'" />
                                                  <downArrowIconSvg v-if="subsubChild.isOpen === true" :class="''" :width="'10px'" :height="'10px'" />
                                                </span>
                                                <span class="pl-2">{{subsubChild.childComponentSingle.childComponentName}}</span>
                                              </div>
                                            </div>
                                            <div  v-for="(subsubsubChild, subsubsubIndex) in subsubChild.childComponentSingle.childComponentDetail" :key="subsubsubIndex" >
                                              <div v-if="subsubChild.openChild">
                                                <div class="flex items-center">
                                                  <p class="flex leading-10" style="width:665px">
                                                    <span class="pl-2">{{subsubsubChild.fieldDisplayName}}</span>
                                                  </p>
                                                </div>
                                                <Editor v-model="subsubsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="data.isOpen === true && subsubsubChild.fieldDataType !== 'string' && subsubsubChild.fieldDataType !== 'integer' && subsubsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                                <textarea v-model="subsubsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                                <input v-model="subsubsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                                <span v-if="data.isOpen === true && subsubsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                                  <button :class="subsubsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubsubChild.fieldDataDetail = 'true'">Yes</button>
                                                  <button :class="subsubsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubsubChild.fieldDataDetail = 'false'">No</button>
                                                </span>
                                              </div>
                                            </div>
                                          </div> -->
                                          <div v-if="subsubChild.isChildRepeatable">
                                            <div class="flex items-center">
                                              <p class="flex leading-10" style="width:665px">
                                                <span @click="subsubChild.openChild = !subsubChild.openChild" class="cursor-pointer" v-if="childrenData.isOpen === true && data.isOpen === true && subChildrenData.isOpen === true" >
                                                  <rightArrowIconSvg v-if="subsubChild.openChild === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                  <downArrowIconSvg v-if="subsubChild.openChild === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                </span>
                                                <span class="pl-2">Child Component</span>
                                              </p>
                                            </div>
                                            <div :class="data.isOpen === true && childrenData.isOpen && subChildrenData.isOpen && subsubChild.openChild ? 'heading-4 text-text2 heading-4 border border-gray-400 rounded-md m-2 p-2' : ''" v-for="(subsubsubChild, subsubsubIndex) in subsubChild.repeatedChildComponentList" :key="subsubsubIndex" >
                                              <div v-if="data.isOpen === true && childrenData.isOpen && subChildrenData.isOpen && subsubChild.openChild">
                                                <div class="flex items-center relative">
                                                  <p class="flex leading-10" style="width:665px">
                                                    <span @click="subsubsubChild.innerOpenChild = !subsubsubChild.innerOpenChild" class="cursor-pointer" v-if="childrenData.isOpen === true && data.isOpen === true && subChildrenData.isOpen === true" >
                                                      <rightArrowIconSvg v-if="subsubsubChild.innerOpenChild === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                      <downArrowIconSvg v-if="subsubsubChild.innerOpenChild === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                                    </span>
                                                    <span class="pl-2">{{subsubsubChild.childComponentName}}</span>
                                                  </p>
                                                  <div class="absolute right-2 cursor-pointer" @click="removeChild23(index, indexData, subChildIndex, subsubIndex,subsubsubIndex, subsubsubChild)">
                                                    <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                                  </div>
                                                </div>
                                                <div v-for="(subsubsubsubChild, subsubsubsubIndex) in subsubsubChild.childComponentDetail" :key="subsubsubsubIndex">
                                                  <div v-if="data.isOpen === true && childrenData.isOpen && subChildrenData.isOpen && subsubsubChild.innerOpenChild">
                                                    <div class="flex items-center">
                                                      <p class=" leading-10" style="width:265px">
                                                        <span class="ml-8" >
                                                          {{subsubsubsubChild.fieldDisplayName}}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    <Editor v-model="subsubsubsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType !== 'string' && subsubsubsubChild.fieldDataType !== 'integer' && subsubsubsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                                    <textarea v-model="subsubsubsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                                    <input v-model="subsubsubsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                                    <span v-if="data.isOpen === true && subsubsubsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                                      <button :class="subsubsubsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubsubsubChild.fieldDataDetail = 'true'">Yes</button>
                                                      <button :class="subsubsubsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubsubsubChild.fieldDataDetail = 'false'">No</button>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-center text-primary cursor-pointer my-3" v-if="subsubChild.isChildRepeatable === true" @click="cloneSelectedComp1(subsubChild)">+ Add an entry (Child)</div>
                                        <!-- <div class="text-center text-primary cursor-pointer my-3" v-if="subsubChild.isRepeated === true" @click="cloneSelectedComp1(subsubsubIndex, subChildIndex, indexChildrenData, indexData, index, childrenData, subsubIndex, subsubChild, subChildrenData)">+ Add an entry (Child)</div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </draggable>
                              <div class="text-center text-primary cursor-pointer my-3" v-if="childrenData.isRepeated === true" @click="cloneSelectedComp(indexData, index, childrenData)">+ Add an entry</div>
                            </div>
                          </div>
                        <!-- </draggable> -->
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="deactivePopup" class="popup_overlay">
          <div style="width: 500px;" class="custom_dialog">
            <div class="text-text1 p-3">
              <span class="heading-3">Are You Sure ?</span>
              <p class="text-gray3 heading-4 pt-3">Are you sure want to Deactive <span class="text-primary">{{pageDetailObj.pageName}}</span> page? </p>
            </div>
            <div class="flex justify-end pr-4 bg-blue-400 p-2 mt-3">
              <button class="bg-gray-100 text-black shadow mr-2 rounded-full px-5 py-1.5" @click="deactivePopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="pageActive()">Yes</button>
            </div>
          </div>
        </div>
        <div v-if="activePagePopup" class="popup_overlay">
          <div style="width: 500px;" class="custom_dialog">
            <div class="text-text1 p-3">
              <span class="heading-3">Are You Sure ?</span>
              <p class="text-gray3 heading-4 pt-3">Are you sure want to Active <span class="text-primary">{{pageDetailObj.pageName}}</span> page? </p>
            </div>
            <div class="flex justify-end pr-4 bg-blue-400 p-2 mt-3">
              <button class="bg-gray-100 text-black shadow mr-2 rounded-full px-5 py-1.5" @click="activePagePopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="pageActive()">Yes</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import HRMAPI from '@/View/CMS/api/HRM.js'
import downArrowIconSvg from '@/assets/images/svg/downArrowIconSvg.vue'
import rightArrowIconSvg from '@/assets/images/svg/rightArrowIconSvg.vue'
import topArrowIconSvg from '@/assets/images/svg/topArrowIconSvg.vue'
// import Pagination from '@/View/components/pagination.vue'
import Editor from '@/View/components/editor.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    downArrowIconSvg,
    draggable,
    rightArrowIconSvg,
    topArrowIconSvg,
    Editor,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      cardHeight: 0,
      pageValue: false,
      deactivePopup: false,
      activePagePopup: false,
      IsSuperAdmin: false,
      saveObj: {
        pageMasterId: 0,
        pageContentDetail: []
      },
      isPush: true,
      drag: false,
      mobileView: false,
      pageDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    this.resizeWindowHandler();
    document.title = 'Page Specific Detail'
    this.getPageDetail()
  },
  watch: {
    pageDetailObj: {
      handler () {
        console.log('Watch On:-pageDetailObj', this.pageDetailObj)
      },
      deep: true
    },
    saveObj: {
      handler () {
        console.log('Watch On:-saveObj', this.saveObj)
      },
      deep: true
    }
  },
  methods: {
    activeInActivePopup (value) {
      if (value === true) {
        this.activePagePopup = true
        this.pageValue = value
      } else if (value === false) {
        this.deactivePopup = true
        this.pageValue = value
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      this.cardHeight = window.innerHeight - 160
    },
    removeElementInside (subChildIndex, indexData, index) {
      console.log('indexChildrenData', subChildIndex)
      console.log('indexData', indexData)
      console.log('index', index)
      console.log('this.pageDetailObj', this.pageDetailObj)
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList[subChildIndex])
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList.splice(subChildIndex , 1)
    },
    removeChild (index1, indx2, index3, index4) {
      console.log('removeChild Called', index1, indx2, index3)
      console.log('removeChild23', this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].singleComponentList.componentFieldList[index3].repeatedChildComponentList[index4])
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].singleComponentList.componentFieldList[index3].deletedChildComponentList = []
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].singleComponentList.componentFieldList[index3].deletedChildComponentList.push(this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].singleComponentList.componentFieldList[index3].repeatedChildComponentList[index4])
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].singleComponentList.componentFieldList[index3].repeatedChildComponentList.splice(index4 , 1)
    },
    removeChild23 (index1, indx2, index3, index4, index5 ,subsubChild) {
      console.log('removeChild23', index1, indx2, index3, index4, index5, subsubChild)
      console.log('removeChild23', this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4].repeatedChildComponentList[index5])
      console.log('removeChild23', this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4])
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4].deletedChildComponentList = []
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4].deletedChildComponentList.push(this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4].repeatedChildComponentList[index5])
      this.pageDetailObj.pageSectionList[index1].PageSectionFiledList[indx2].repeatedComponentList[index3].componentFieldList[index4].repeatedChildComponentList.splice(index5 , 1)
    },
    removeElement (indexData, index, childrenData, data) {
      console.log('indexData', indexData)
      console.log('index', index)
      console.log('data', data)
      console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList', this.pageDetailObj)
      if (childrenData.componentMasterId === 0) {
        this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].isDeleted = true
      }
      if (childrenData.componentMasterId > 0) {
        if (childrenData.repeatedComponentList === null) {
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].singleComponentList)
          childrenData.isOpen = false
          childrenData.isDeleted = true
          console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData]', this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData])
        } else {
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
          for (let I = 0; I < this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList.length; I++) {
            this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList[I])
          }
          childrenData.isDeleted = true
          console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData]', this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData])
        }
      }
      console.log('this.saveObj.saveObj', this.saveObj.pageContentDetail)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    redirectToList() {
      this.$router.push({name: 'specificProject', params: {projectId: parseInt(this.$route.params.projectId)}})
    },
    getPageDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.getContentDetail(
        parseInt(this.$route.params.pageMasterId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    pageActive () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.pageActiveInactive(
        parseInt(this.$route.params.pageMasterId),
        this.pageValue,
        response => {
          console.log("response 123", response.Data)
          this.activePagePopup = false
          this.deactivePopup = false
          this.$router.push({name: 'specificProject', params: {projectId: parseInt(this.$route.params.projectId)}})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dragArray () {
      this.drag = false
    },
    cloneSelectedComp1 (data2) {
      console.log('data2', data2)
      console.log('data2', data2.isChildRepeatable)
      console.log('data2', data2.singleChildComponentList)
      if (data2.singleChildComponentList !== null) {
        if (data2.isChildRepeatable === true) {
          let Obj = JSON.parse(JSON.stringify(data2.singleChildComponentList))
          data2.repeatedChildComponentList.push(Obj)
        }
      }
    },
    cloneSelectedComp (childIndex, Index, data2) {
      console.log('childIndex', childIndex)
      console.log('Index', Index)
      console.log('data2', data2)
      console.log('this.pageDetailObj', this.pageDetailObj.pageSectionList[Index].PageSectionFiledList[childIndex].repeatedComponentList)
      if (data2.singleComponentList !== null) {
        if (data2.isRepeated === true) {
          let Obj = JSON.parse(JSON.stringify(data2.singleComponentList))
          console.log('Obj', Obj)
          for (let index = 0; index < Obj.componentFieldList.length; index++) {
            Obj.componentFieldList[index].repeatedChildComponentList = []
          }
          data2.repeatedComponentList.push(Obj)
        }
      }
    },
    updatePageStr () {
      this.$router.push({name: 'EditPage', params: {projectId: parseInt(this.$route.params.projectId), pageMasterId: parseInt(this.$route.params.pageMasterId)}})
    },
    saveComponentData () {
      console.log('click on save button', this.pageDetailObj)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.savepageContant(
        parseInt(this.$route.params.pageMasterId),
        this.pageDetailObj,
        response => {
          console.log("response 123", response)
          this.getPageDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.category_name_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.category_name_col_treeview {
  /* min-width: 400px !important; */
  width: 800px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.parentcategory_name_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.editicon_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.list_top_card_btn {
  width: 133px !important;
  margin-right: 15px !important;
}
</style>
